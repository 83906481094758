import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Link, useStaticQuery, graphql } from "gatsby"

import { revealFromTop, revealCloseY } from "./animations"
import { Card } from "../anti/card/card"
// import { CardSlider } from "../ak-card-slider/card-slider"

const DesktopSubmenu = ({
  desktopSubmenu,
  handleDesktopSubmenu,
  navExpand,
  lang,
  menus,
}) => {
  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)

  //Featured Project
  const data = useStaticQuery(graphql`
    query {
      imgCover: file(relativePath: { eq: "sample/bg_rectangle-dark-2.jpg" }) {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "development-landing") {
          id
          blocks {
            ... on WordPress_AcfDevelopmentLandingBlock {
              developmentLanding {
                name
                cover {
                  title
                  img {
                    sourceUrl(size: MEDIUM)
                  }
                  label
                }
                landing {
                  title
                  text
                }
              }
            }
          }
        }
        projectTags(where: { name: "Featured" }) {
          nodes {
            projects {
              nodes {
                title
                slug
                projectTags {
                  nodes {
                    name
                  }
                }
                propertyTypes {
                  nodes {
                    name
                    slug
                  }
                }
                blocks {
                  name
                  ... on WordPress_AcfCoverMainBlock {
                    attributes {
                      className
                    }
                    coverMain {
                      img {
                        sourceUrl
                      }
                      label
                      title
                      text
                      imgOverlay
                      buttons {
                        text
                        link {
                          url
                          target
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.wordPress.projectTags.nodes[0].projects.nodes

  // Reveal & close animation
  useEffect(() => {
    if (desktopSubmenu.clicked === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if (
      desktopSubmenu.clicked === true ||
      (desktopSubmenu.clicked === true && desktopSubmenu.initial === null)
    ) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, {
        duration: 0,
        opacity: 1,
        height: "auto",
      })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [desktopSubmenu])

  const childItems = lang === "EN"
    ? ((menus
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("develop")
      )
    )?.childItems.nodes
    : ((menus
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      .menuItems.nodes.find(el =>
        el.url === "#" && (el.label.toLowerCase().includes("bangunan") || el.label.toLowerCase().includes("embang"))
      )
    )?.childItems.nodes
  return (
    <>
      <div className={`d-none d-${navExpand}-block`}>
        <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
          <div
            className="navbar-submenu-bg"
            ref={el => (menuBackground = el)}
            onClick={handleDesktopSubmenu}
          />
          <div
            className="navbar-submenu-content"
            ref={el => (menuContent = el)}
          >
            <div className="navbar-submenu-collapse">
              <div className="navbar-submenu-collapse-header">
                <button className="btn btn-link" onClick={handleDesktopSubmenu}>
                  {lang == "EN" ? "Back" : "Kembali"}
                </button>
              </div>
              <div className="navbar-submenu-collapse-content">
                <div className={`row row-3 ${projects.length == 0 || projects == null || projects == undefined ? 'w-100' : ''}`}>
                  <div className="col-md-4">
                    <h2 ref={el => (line1 = el)}>{lang == "EN" ? "Development" : "Pembangunan"}</h2>
                    <p ref={el => (line2 = el)}>
                      {lang == "EN" ? "Discover BSD City current & future developments" : "Temukan pengembangan BSD City saat ini dan masa datang. "}
                    </p>
                    <ul className="navbar-nav">

                      {childItems?.map((item, i) => {
                        return (
                          <li key={i} className="nav-item">
                            <div ref={el => (line3 = el)}>
                              {/* prettier-ignore */}
                              <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                                {item.label}
                              </Link>
                            </div>
                          </li>
                        )
                      })}

                    </ul>
                  </div>
                  <div className="col-md-8">
                    <CardList data={projects} lang={lang} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CardList = ({ data, lang }) => {
  return (
    <div className="d-none d-lg-block">
      <div className="row">
        {data.map((block, i) => {
          if (i === 0) {
            return (
              <div key={`${block.title}-${i}`} className="col-md-4">
                <Card
                  variant="overlay"
                  title={block.title}
                  titleClassName="h5"
                  label={
                    (block.projectTags.nodes.find(x => x.name !== "Featured") &&
                      block.projectTags.nodes.find(x => x.name !== "Featured")
                        .name) ||
                    ""
                  }
                  text={block.propertyTypes.nodes[0].name}
                  img={block.blocks[0].coverMain.img.sourceUrl}
                  imgHeight="h-ratio-1-1"
                  className="card-property submenu-property"
                  link={`${lang === "EN" ? "" : "/id"}/development/${block.propertyTypes.nodes[0].slug}/${block.slug}`}
                />
              </div>
            )
          } else if (i > 0 && i <= 2) {
            return (
              <div key={`${block.title}-${i}`} className="col-md-4">
                <Card
                  variant="overlay"
                  title={block.title}
                  titleClassName="h5"
                  label={
                    (block.projectTags.nodes.find(x => x.name !== "Featured") &&
                      block.projectTags.nodes.find(x => x.name !== "Featured")
                        .name) ||
                    ""
                  }
                  text={block.propertyTypes.nodes[0].name}
                  img={
                    block.blocks[0].coverMain.img &&
                    block.blocks[0].coverMain.img.sourceUrl
                  }
                  imgHeight="h-ratio-1-1"
                  className="card-property submenu-property"
                  link={`/development/${block.propertyTypes.nodes[0].slug}/${block.slug}`}
                />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default DesktopSubmenu

import axios from "axios"

const cname = "utm_params="
const utmKeys = ["utm_source", "utm_campaign", "utm_medium", "utm_content"]

export function getUtmParams(location) {
  if (location) {
    const utmParams = location.substring(1)
    const params = Object.fromEntries(new URLSearchParams(utmParams))
    if (isUTMParams(params)) {
      document.cookie = cname + JSON.stringify(params) + "; path=/"
    }
  }
}

export function extractCookie(cname) {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cname))
    ?.split("=")[1]

  if (cookieValue) {
    return JSON.parse(cookieValue)
  } else {
    return null
  }
}

function checkACookieExists(cname) {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith(cname))
}

function isUTMParams(param) {
  const keys = Object.keys(param)
  return utmKeys.some((item) => keys.includes(item))
}

function deleteUtm(cname) {
  document.cookie = cname + "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
}

export async function submitUtm(params) {
  //params.fullname
  //params.email
  //params.mobile
  //params.project_name
  //params.projects_code
  //params.cluster_code
  //params.lead_source_name
  //params.web

  const utmParams = extractCookie(cname)
  const data = {
    ...utmParams,
    ...params,
  }
  console.log(data)

  const config = {
    method: "POST",
    url: process.env.CPI_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  }

  const res = await axios(config)
  deleteUtm(cname)
  console.log(res)
}

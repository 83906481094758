import React, { useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { gsap } from "gsap"

import { revealFromTop, revealCloseY } from "./animations"
import { Button } from "../anti/buttons/buttons"
// import { CardSlider } from "../ak-card-slider/card-slider"

const DesktopAboutSubmenu = ({
  desktopSubmenu,
  handleDesktopSubmenu,
  navExpand,
  data,
  menus,
  lang
}) => {
  // MOBILE SUBMENU - Animation
  // Element ref.
  let aboutMenuWrapper = useRef(null)
  let aboutMenuBackground = useRef(null)
  let aboutMenuContent = useRef(null)
  // Reveal & close animation
  const childItems = lang === "EN"
    ? ((menus
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("about")
      )
    )?.childItems.nodes
    : ((menus
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("tentang")
      )
    )?.childItems.nodes

  const subMenu =
    data &&
    data.childItems.nodes.length > 0 &&
    data.childItems.nodes.find(el => el.url === "/investor" || el.url === "/id/investor").childItems?.nodes
  useEffect(() => {
    if (desktopSubmenu.clicked === false) {
      revealCloseY(aboutMenuContent)
      gsap.to(aboutMenuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(aboutMenuWrapper, { duration: 1, css: { display: "none" } })
    } else if (
      desktopSubmenu.clicked === true ||
      (desktopSubmenu.clicked === true && desktopSubmenu.initial === null)
    ) {
      gsap.to(aboutMenuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(aboutMenuContent, {
        duration: 0,
        opacity: 1,
        height: "auto",
      })
      gsap.to(aboutMenuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(aboutMenuContent)
    }
  }, [desktopSubmenu])
  return (
    <>
      <div className={`d-none d-${navExpand}-block`}>
        <div
          className="navbar-submenu light"
          ref={el => (aboutMenuWrapper = el)}
        >
          <div
            className="navbar-submenu-bg"
            ref={el => (aboutMenuBackground = el)}
            onClick={handleDesktopSubmenu}
          />
          <div
            className="navbar-submenu-content"
            ref={el => (aboutMenuContent = el)}
          >
            <div className="navbar-submenu-collapse">
              <div className="navbar-submenu-collapse-header">
                <button className="btn btn-link" onClick={handleDesktopSubmenu}>
                  {lang == "EN" ? "Back" : "Kembali"}
                </button>
              </div>
              <div className="navbar-submenu-collapse-content">
                <div className="container">
                  <h2>{lang == "EN" ? "About BSD City" : "Tentang BSD CIty"}</h2>
                  <div className="row w-100">
                    <div className="col-md-4">
                      <ul className="navbar-nav">

                        {childItems?.map((item, i) => {
                          return (
                            <li key={i} className="nav-item">
                              {/* prettier-ignore */}
                              <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                                {item.label}
                              </Link>
                            </li>
                          )
                        })}

                      </ul>
                    </div>
                    <div className="col-md-8">
                      <h5 className="text-dark">{lang == "EN" ? "Investor's Area" : "Area Investor"}</h5>
                      <div className="row">
                        <div className="col-7">
                          <ul className="about-submenu">
                            {data &&
                              subMenu &&
                              subMenu.map((item, i) => {
                                if (i < 4) {
                                  return (
                                    <li
                                      className="about-submenu-item"
                                      key={`${item.label}${i}`}
                                    >
                                      <Link to={`${item.url}/`} className="btn-hover">
                                        {item.label}
                                      </Link>
                                    </li>
                                  )
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-5">
                          <ul className="about-submenu">
                            {subMenu &&
                              subMenu.map((item, i) => {
                                if (i >= 4) {
                                  return (
                                    <li
                                      className="about-submenu-item"
                                      key={`${item.label}${i}`}
                                    >
                                      <Link to={`${item.url}/`} className="btn-hover">
                                        {item.label}
                                      </Link>
                                    </li>
                                  )
                                }
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <Button
                        variant="secondary btn-block"
                        onClick={() => navigate(`${lang === "EN" ? "" : "/id"}/contact-us/`)}
                      >
                        {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopAboutSubmenu

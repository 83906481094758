import React, { useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import { staggerBackgroundIn, staggerBackgroundOut } from "./animations"
import { Button } from "../anti/buttons/buttons"

const MobileAboutSubmenu = ({
  mobileSubmenu,
  handleMobileSubmenu,
  navExpand,
  data,
  lang,
  menusMobile,
  isAmp,
}) => {
  // MOBILE SUBMENU
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)

  const menusEN = data && data.wordPress.menus.nodes[2].menuItems.nodes.find(
    x => x.label === "About BSD City"
  )
  const menusID = data && data.wordPress.menus.nodes[3].menuItems.nodes.find(
    x => x.label === "Tentang Kota BSD City"
  )
  const subMenus =
    menusEN && menusEN.childItems.nodes.find(x => x.label === "Investor's Area")

  const subMenusID =
    menusID && menusID.childItems.nodes.find(x => x.label === "Area Investor")

  const childItems = lang === "EN"
    ? ((menusMobile
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("about")
      )
    )?.childItems.nodes
    : ((menusMobile
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("tentang")
      )
    )?.childItems.nodes

  // Reveal & close animation
  useEffect(() => {
    if (mobileSubmenu.clicked === false) {
      // staggerContentOut(line7, line6, line5, line4, line3, line2, line1)
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
    } else if (
      mobileSubmenu.clicked === true ||
      (mobileSubmenu.clicked === true && mobileSubmenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      // staggerContentIn(line1, line2, line3, line4, line5, line6, line7)
    }
  }, [mobileSubmenu])

  return (
    <>
      {isAmp? (
        <amp-sidebar id={`side${lang === "EN" ? menusEN.label.replace(/\s/g, '') : menusID.label.replace(/\s/g, '')}`} layout="nodisplay" side="right">
          <div role="button" aria-label="close sidebar" on={`tap:side${lang === "EN" ? menusEN.label.replace(/\s/g, '') : menusID.label.replace(/\s/g, '')}.toggle`} tabindex="0" class="close-sidebar-left">{lang == "EN" ? "Back" : "Kembali"}</div>
          <ul className="navbar-nav-about mb-3">
            {childItems?.map((item, i) => {
              return (
                <li key={i} className="nav-item">
                  <div ref={el => (line3 = el)}>
                    {/* prettier-ignore */}
                    <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                      {item.label}
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
          <h5 ref={el => (line5 = el)}>{lang == "EN" ? "Investor's Area" : "Area Investor"}</h5>
          <ul className="about-submenu">
            {
              lang == "EN" &&
              subMenus &&
              subMenus.childItems.nodes.map(item => (
                <li className="about-submenu-item" key={item.label}>
                  <Link to={item.url+"/"}>{item.label}</Link>
                </li>
              ))
            }
            {
              lang == "ID" &&
              subMenusID &&
              subMenusID.childItems.nodes.map(item => (
                <li className="about-submenu-item" key={item.label}>
                  <Link to={item.url+"/"}>{item.label}</Link>
                </li>
              ))
            }
          </ul>
          <ul className="contact-us-wrapper">
            <li>
              <Button
                variant="secondary btn-block"
                onClick={() => navigate("/contact-us/")}
              >
                {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
              </Button>
            </li>
          </ul>
        </amp-sidebar>
      ) : (
        <div className={`d-block d-${navExpand}-none`}>
          <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
            <div
              className="navbar-submenu-bg"
              ref={el => (menuBackground = el)}
              onClick={handleMobileSubmenu}
            />
            <div
              className="navbar-submenu-content"
              ref={el => (menuContent = el)}
            >
              <div className="navbar-submenu-collapse">
                <div className="navbar-submenu-collapse-header">
                  <button className="btn btn-link" onClick={handleMobileSubmenu}>
                    {lang == "EN" ? "Back" : "Kembali"}
                  </button>
                </div>
                <div className="navbar-submenu-collapse-content d-block">
                  {/* <div className="overflow-hidden">
                    <h2 ref={el => (line1 = el)}>About BSD City</h2>
                  </div> */}
                  <ul className="navbar-nav mb-3">
                    {childItems?.map((item, i) => {
                      return (
                        <li key={i} className="nav-item">
                          <div ref={el => (line3 = el)}>
                            {/* prettier-ignore */}
                            <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                              {item.label}
                            </Link>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  <h5 ref={el => (line5 = el)}>{lang == "EN" ? "Investor's Area" : "Area Investor"}</h5>
                  <ul className="about-submenu">
                    {
                      lang == "EN" &&
                      subMenus &&
                      subMenus.childItems.nodes.map(item => (
                        <li className="about-submenu-item" key={item.label}>
                          <Link to={item.url+"/"}>{item.label}</Link>
                        </li>
                      ))
                    }
                    {
                      lang == "ID" &&
                      subMenusID &&
                      subMenusID.childItems.nodes.map(item => (
                        <li className="about-submenu-item" key={item.label}>
                          <Link to={item.url+"/"}>{item.label}</Link>
                        </li>
                      ))
                    }
                  </ul>
                  <Button
                    variant="secondary btn-block"
                    onClick={() => navigate("/contact-us/")}
                  >
                    {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileAboutSubmenu
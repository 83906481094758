import React, { useEffect, useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { staggerBackgroundIn, staggerBackgroundOut } from "./animations"
import { Card, CardAmp } from "../anti/card/card"

const MobileSubmenu = ({ mobileSubmenu, handleMobileSubmenu, navExpand, lang, menusMobile, datalist, isAmp }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCover: file(relativePath: { eq: "sample/bg_rectangle-dark-2.jpg" }) {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "development-landing") {
          id
          blocks {
            ... on WordPress_AcfDevelopmentLandingBlock {
              developmentLanding {
                name
                cover {
                  title
                  img {
                    sourceUrl(size: MEDIUM)
                  }
                  label
                }
                landing {
                  title
                  text
                }
              }
            }
          }
        }
        projectTags(where: { name: "Featured" }) {
          nodes {
            projects {
              nodes {
                title
                slug
                projectTags {
                  nodes {
                    name
                  }
                }
                propertyTypes {
                  nodes {
                    name
                    slug
                  }
                }
                blocks {
                  name
                  ... on WordPress_AcfCoverMainBlock {
                    attributes {
                      className
                    }
                    coverMain {
                      img {
                        sourceUrl
                      }
                      label
                      title
                      text
                      imgOverlay
                      buttons {
                        text
                        link {
                          url
                          target
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // const wp = data.wordPress.pageBy.blocks
  const menusEN = datalist && datalist.wordPress.menus.nodes[2].menuItems.nodes.find(
    x => x.label === "Developments"
  )
  const menusID = datalist && datalist.wordPress.menus.nodes[3].menuItems.nodes.find(
    x => x.label === "Pembangunan"
  )
  const projects = data.wordPress.projectTags.nodes[0].projects.nodes
  const childItems = lang === "EN"
    ? ((menusMobile
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("develop")
      )
    )?.childItems.nodes
    : ((menusMobile
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      ?.menuItems?.nodes.find(el =>
        el.url === "#" && (el.label.toLowerCase().includes("bangunan") || el.label.toLowerCase().includes("embang"))
      )
    )?.childItems.nodes

  // MOBILE SUBMENU
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)


  // Reveal & close animation
  useEffect(() => {
    if (mobileSubmenu.clicked === false) {
      // staggerContentOut(line7, line6, line5, line4, line3, line2, line1)
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
    } else if (
      mobileSubmenu.clicked === true ||
      (mobileSubmenu.clicked === true && mobileSubmenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      // staggerContentIn(line1, line2, line3, line4, line5, line6, line7)
    }
  }, [mobileSubmenu])

  return (
    <>
      {isAmp ? (
        <amp-sidebar id={`side${lang === "EN" ? menusEN.label.replace(/\s/g, '') : menusID.label.replace(/\s/g, '')}`} layout="nodisplay" side="right">
          <div role="button" aria-label="close sidebar" on={`tap:side${lang === "EN" ? menusEN.label.replace(/\s/g, '') : menusID.label.replace(/\s/g, '')}.toggle`} tabindex="0" class="close-sidebar-left">{lang == "EN" ? "Back" : "Kembali"}</div>
          <ul className="navbar-nav-developments mb-3">
            <li><h2 ref={el => (line1 = el)}>{lang == "EN" ? "Development" : "Pembangunan"}</h2></li>
            <li><span>{lang == "EN" ? "Discover BSD City current & future developments" : "Temukan pengembangan BSD City saat ini dan masa datang."}</span></li>
            {childItems?.map((item, i) => {
              return (
                <li key={i} className="nav-item">
                  <div ref={el => (line3 = el)}>
                    {/* prettier-ignore */}
                    <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                      {item.label}
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
          <ul className="submenu-list-card">
            {projects.map((block, i) => {
              if (i === 0) {
                return (
                  <CardAmp
                    key={`mobile-${block.title}-${i}`}
                    variant="overlay"
                    title={block.title}
                    titleClassName="h5"
                    label={
                      (block.projectTags.nodes.find(
                        x => x.name !== "Featured"
                      ) &&
                        block.projectTags.nodes.find(
                          x => x.name !== "Featured"
                        ).name) ||
                      ""
                    }
                    text={block.propertyTypes.nodes[0].name}
                    img={block.blocks[0].coverMain.img.sourceUrl}
                    imgHeight="h-ratio-2-1"
                    className="card-property submenu-property mb-3"
                    link={`${lang === "EN" ? "" : "/id"}/development/${block.propertyTypes.nodes[0].slug}/${block.slug}/`}
                  />
                )
              } else if (i > 0 && i <= 2) {
                return (
                  <CardAmp
                    key={`mobile-${block.title}-${i}`}
                    variant="overlay"
                    title={block.title}
                    titleClassName="h5"
                    label={
                      (block.projectTags.nodes.find(
                        x => x.name !== "Featured"
                      ) &&
                        block.projectTags.nodes.find(
                          x => x.name !== "Featured"
                        ).name) ||
                      ""
                    }
                    text={block.propertyTypes.nodes[0].name}
                    img={
                      block.blocks[0].coverMain.img &&
                      block.blocks[0].coverMain.img.sourceUrl
                    }
                    imgHeight="h-ratio-2-1"
                    className="card-property submenu-property mb-3"
                    link={`${lang === "EN" ? "" : "/id"}/development/${block.propertyTypes.nodes[0].slug}/${block.slug}/`}
                  />
                )
              }
            })}
          </ul>
        </amp-sidebar>
      ) : (
        <div className={`d-block d-${navExpand}-none`}>
          <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
            <div
              className="navbar-submenu-bg"
              ref={el => (menuBackground = el)}
              onClick={handleMobileSubmenu}
            />
            <div
              className="navbar-submenu-content"
              ref={el => (menuContent = el)}
            >
              <div className="navbar-submenu-collapse">
                <div className="navbar-submenu-collapse-header">
                  <button className="btn btn-link" onClick={handleMobileSubmenu}>
                    {lang == "EN" ? "Back" : "Kembali"}
                  </button>
                </div>
                <div className="navbar-submenu-collapse-content d-block">
                  <div className="overflow-hidden">
                    <h2 ref={el => (line1 = el)}>{lang == "EN" ? "Development" : "Pembangunan"}</h2>
                  </div>
                  <div className="overflow-hidden">
                    <p ref={el => (line2 = el)}>
                      {lang == "EN" ? "Discover BSD City current & future developments" : "Temukan pengembangan BSD City saat ini dan masa datang."}
                    </p>
                  </div>
                  <ul className="navbar-nav mb-3">
                    {childItems?.map((item, i) => {
                      return (
                        <li key={i} className="nav-item">
                          <div ref={el => (line3 = el)}>
                            {/* prettier-ignore */}
                            <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                              {item.label}
                            </Link>
                          </div>
                        </li>
                      )
                    })}

                  </ul>
                  <div className="submenu-card-list">
                    {projects.map((block, i) => {
                      if (i === 0) {
                        return (
                          <Card
                            key={`mobile-${block.title}-${i}`}
                            variant="overlay"
                            title={block.title}
                            titleClassName="h5"
                            label={
                              (block.projectTags.nodes.find(
                                x => x.name !== "Featured"
                              ) &&
                                block.projectTags.nodes.find(
                                  x => x.name !== "Featured"
                                ).name) ||
                              ""
                            }
                            text={block.propertyTypes.nodes[0].name}
                            img={block.blocks[0].coverMain.img.sourceUrl}
                            imgHeight="h-ratio-2-1"
                            className="card-property submenu-property mb-3"
                            link={`${lang === "EN" ? "" : "/id"}/development/${block.propertyTypes.nodes[0].slug}/${block.slug}/`}
                          />
                        )
                      } else if (i > 0 && i <= 2) {
                        return (
                          <Card
                            key={`mobile-${block.title}-${i}`}
                            variant="overlay"
                            title={block.title}
                            titleClassName="h5"
                            label={
                              (block.projectTags.nodes.find(
                                x => x.name !== "Featured"
                              ) &&
                                block.projectTags.nodes.find(
                                  x => x.name !== "Featured"
                                ).name) ||
                              ""
                            }
                            text={block.propertyTypes.nodes[0].name}
                            img={
                              block.blocks[0].coverMain.img &&
                              block.blocks[0].coverMain.img.sourceUrl
                            }
                            imgHeight="h-ratio-2-1"
                            className="card-property submenu-property mb-3"
                            link={`${lang === "EN" ? "" : "/id"}/development/${block.propertyTypes.nodes[0].slug}/${block.slug}/`}
                          />
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileSubmenu
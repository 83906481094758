import React, { useState, useEffect, useRef, useContext } from "react"
import { Link } from "gatsby"

import { useAuthContext } from "../../context/AuthContext"

import { SocialLinks } from "../anti/social-links/social-links"

import {
  staggerBackgroundIn,
  staggerBackgroundOut,
  staggerContentIn,
  staggerContentOut,
} from "./animations"
import { LangContext } from "../../context/LangContext"
import MobileSubmenu from "./mobile-submenu"
import MobileAboutSubmenu from "./mobile-about-submenu"
import MobileServicesSubmenu from "./mobile-services"

const MobileMenuAlt = ({ mobileMenu, navExpand, data, handlerLogout }) => {
  const { lang, setLang } = useContext(LangContext)

  // MOBILE SUBMENU - State & handler
  // Open / close submenu state & handler
  const [mobileSubmenu, setMobileSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [mobileAboutSubmenu, setMobileAboutSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [mobileServicesSubmenu, setMobileServicesSubmenu] = useState({
    initial: false,
    clicked: null,
  })

  const handleMobileSubmenu = () => {
    disableSubmenuToggler()
    if (mobileSubmenu.initial === false) {
      setMobileSubmenu({ initial: null, clicked: true })
    } else if (mobileSubmenu.clicked === true) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    } else if (mobileSubmenu.clicked === false) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    }
  }
  const handleMobileAboutSubmenu = () => {
    disableSubmenuAboutToggler()
    if (mobileAboutSubmenu.initial === false) {
      setMobileAboutSubmenu({ initial: null, clicked: true })
    } else if (mobileAboutSubmenu.clicked === true) {
      setMobileAboutSubmenu({ clicked: !mobileAboutSubmenu.clicked })
    } else if (mobileAboutSubmenu.clicked === false) {
      setMobileAboutSubmenu({ clicked: !mobileAboutSubmenu.clicked })
    }
  }
  const handleMobileServicesSubmenu = () => {
    disableSubmenuServicesToggler()
    if (mobileServicesSubmenu.initial === false) {
      setMobileServicesSubmenu({ initial: null, clicked: true })
    } else if (mobileServicesSubmenu.clicked === true) {
      setMobileServicesSubmenu({ clicked: !mobileServicesSubmenu.clicked })
    } else if (mobileServicesSubmenu.clicked === false) {
      setMobileServicesSubmenu({ clicked: !mobileServicesSubmenu.clicked })
    }
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuAboutToggler, setSubmenuAboutToggler] = useState(false)
  const disableSubmenuAboutToggler = () => {
    setSubmenuAboutToggler(!submenuAboutToggler)
    setTimeout(() => {
      setSubmenuAboutToggler(false)
    }, 1000)
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuServicesToggler, setSubmenuServicesToggler] = useState(false)
  const disableSubmenuServicesToggler = () => {
    setSubmenuServicesToggler(!submenuServicesToggler)
    setTimeout(() => {
      setSubmenuServicesToggler(false)
    }, 1000)
  }

  const { user } = useAuthContext()
  // MOBILE MENU - Animation
  // Element ref.
  let menuWrapper = useRef(null) // Reveal 1
  let menuBackground = useRef(null) // Reveal 2
  let menuContent = useRef(null) // Reveal 3
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)

  const staggerGasp = (isClicked) => {
    const lines = []
    if (!user) {
      lines.push(line2, line3, line6)
    } else if (user) {
      lines.push(line4, line5, line6)
    }
    if (isClicked) {
      staggerContentIn(lines)
    } else {
      staggerContentOut(lines.reverse())
    }
  }

  // Reveal & close animation
  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler")
    const mainElmnt = document.querySelector("main")
    if (mobileMenu.clicked === false) {
      staggerGasp(mobileMenu.clicked)
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
      navbarToggler.classList.remove("open", "dark")
      mainElmnt.classList.remove("overflow-hidden")
    } else if (
      mobileMenu.clicked === true ||
      (mobileMenu.clicked === true && mobileMenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      staggerGasp(mobileMenu.clicked)
      navbarToggler.classList.add("open", "dark")
      setTimeout(() => mainElmnt.classList.add("overflow-hidden"), 200)
    }
  }, [mobileMenu])

  return (
    <>
      <div
        className={`navbar-menu light d-${navExpand}-none`}
        ref={(el) => (menuWrapper = el)}
      >
        <div
          className="navbar-menu-bg"
          ref={(el) => (menuBackground = el)}
        ></div>
        <div className="navbar-menu-content" ref={(el) => (menuContent = el)}>
          <div className="navbar-collapse">
            <ul className="navbar-nav ml-auto">
              {data.wordPress.menus.nodes.map((menuSections, i) => {
                // Navbar ENGLISH
                if (lang === "EN" && menuSections.slug === "navbar-menu") {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("about")
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuAboutToggler}
                              onClick={() => {
                                handleMobileAboutSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("development")
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuToggler}
                              onClick={() => {
                                handleMobileSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("services")
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuServicesToggler}
                              onClick={() => {
                                handleMobileServicesSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li className={`nav-item`}>
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={menu.label.toLowerCase().includes("home") ? menu.url : menu.url+"/"}
                            key={`${menu.label}-${j}`}
                          >
                            {menu.label}
                          </Link>
                        </div>
                      </li>
                      // <div ref={el => (line7 = el)}></div>
                    )
                  })
                  // Navbar INDO
                } else if (
                  lang === "ID" &&
                  (menuSections.slug === "navbar-menu-indonesia" ||
                    menuSections.slug === "navbar-menu-id")
                ) {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("tentang")
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuAboutToggler}
                              onClick={() => {
                                handleMobileAboutSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      (menu.label.toLowerCase().includes("embangan") ||
                        menu.label.toLowerCase().includes("pembangun"))
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuToggler}
                              onClick={() => {
                                handleMobileSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      (menu.url === "#" &&
                        menu.label.toLowerCase().includes("layanan")) ||
                      menu.label.toLowerCase().includes("jasa")
                    ) {
                      return (
                        <li className={`nav-item`} key={`${menu.label}${j}`}>
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuServicesToggler}
                              onClick={() => {
                                handleMobileServicesSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li className={`nav-item`}>
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={menu.label.toLowerCase().includes("beranda") ? menu.url : menu.url+"/"}
                            key={`${menu.label}-${j}`}
                          >
                            {menu.label}
                          </Link>
                        </div>
                      </li>
                      // <div ref={el => (line7 = el)}></div>
                    )
                  })
                }
              })}
              {!user ? (
                <div className="row mt-3">
                  <div className="col-6" ref={(el) => (line2 = el)}>
                    <Link className="btn btn-secondary btn-block pb-0 pt-1 register-btn-header" to="/register/">
                      Register
                    </Link>
                  </div>
                  <div className="col-6" ref={(el) => (line3 = el)}>
                    <Link className="btn btn-secondary btn-block pb-0 pt-1 subscribe-btn-header" to="/subscribe/">
                      Subscribe
                    </Link>
                  </div>
                </div>
                // <>
                //   <li className="nav-item">
                //     <div ref={(el) => (line1 = el)}>
                //       prettier-ignore
                //       <Link className="nav-link" activeClassName="active" to="/login" >
                //    Login
                //   </Link>
                //     </div>
                //   </li> 
                //   <li className="nav-item">
                //     <div ref={(el) => (line2 = el)}>
                //       <Link
                //         className="nav-link"
                //         activeClassName="active"
                //         to="/register"
                //       >
                //         Register
                //       </Link>
                //     </div>
                //   </li>
                //   <li className="nav-item">
                //     <div ref={(el) => (line3 = el)}>
                //       <Link
                //         className="nav-link"
                //         activeClassName="active"
                //         to="/subscribe"
                //       >
                //         Subscribe
                //       </Link>
                //     </div>
                //   </li>
                // </>
              ) : (
                <>
                  <li className="nav-item">
                    <div ref={(el) => (line4 = el)}>
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/profile/"
                      >
                        Profile
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div ref={(el) => (line5 = el)}>
                      <button
                        className="nav-link"
                        onClick={() => handlerLogout()}
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </>
              )}
            </ul>
            <div className="social-links-wrapper">
              <div ref={(el) => (line6 = el)}>
                <SocialLinks data={data.wordPress.menus.nodes} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileSubmenu
        menusMobile={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        mobileSubmenu={mobileSubmenu}
        handleMobileSubmenu={handleMobileSubmenu}
        navExpand={navExpand}
        lang={lang}
      />
      <MobileAboutSubmenu
        menusMobile={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        mobileSubmenu={mobileAboutSubmenu}
        handleMobileSubmenu={handleMobileAboutSubmenu}
        navExpand={navExpand}
        data={data}
        lang={lang}
      />
      <MobileServicesSubmenu
        menusMobile={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        mobileSubmenu={mobileServicesSubmenu}
        handleMobileSubmenu={handleMobileServicesSubmenu}
        navExpand={navExpand}
        data={data}
        lang={lang}
      />
    </>
  )
}

export default MobileMenuAlt

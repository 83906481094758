import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import { navigate, withPrefix } from "gatsby"
import Helmet from "react-helmet"
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown"
import Modal from "react-modal"
import { useQuery } from "@apollo/react-hooks"
import moment from "moment"

import NavbarAlt from "./navbar/navbar-alt"
import { PopupContext } from "../context/PopupContext"
import { Button } from "../components/anti/buttons/buttons"
import Navbar from "./navbar/navbar"
import Footer from "./footer/footer"

import { getUtmParams } from "../utils/handle-utm"
import { LangContext } from "../context/LangContext"

const GET_DATA_POPUP = gql`
  query getDataPopup {
    page(id: "home", idType: URI) {
      translation(language: ID) {
        pop_up {
          listPopUp {
            ... on Page_PopUp_ListPopUp_PopUpContent {
              startDate
              endDate
              title
              description
              image {
                sourceUrl
              }
              ctaLabel
              ctaLink {
                target
                title
                url
              }
            }
          }
        }
      }
      pop_up {
        listPopUp {
          ... on Page_PopUp_ListPopUp_PopUpContent {
            startDate
            endDate
            title
            description
            image {
              sourceUrl
            }
            ctaLabel
            ctaLink {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`

const ModalTag = ({ dataModal, handlingModal, thisModal, stylingModal }) => {
  const now = moment().utcOffset("+07:00")
  let counter = 0
  let developmentPopup = []
  let popupActiveList = []
  let keyList = []
  let keyShowing

  let alreadyDone = []
  const randomValueFromArray = myArray => {
    if (alreadyDone.length === 0) {
      for (var i = 0; i < myArray.length; i++) alreadyDone.push(i)
    }

    let randomValueIndex = Math.floor(Math.random() * alreadyDone.length)
    let indexOfItemInMyArray = alreadyDone[randomValueIndex]
    alreadyDone.splice(randomValueIndex, 1)

    return myArray[indexOfItemInMyArray]
  }

  dataModal &&
    dataModal.map((item, i) => {
      if (
        moment(dataModal[i].endDate).utcOffset("+07:00", true) > now &&
        moment(dataModal[i].startDate).utcOffset("+07:00", true) <= now
      ) {
        popupActiveList.push({
          key: i,
          startDate: moment(item.startDate).utcOffset("+07:00", true),
          endDate: moment(item.endDate).utcOffset("+07:00", true),
          title: item.title,
          description: item.description,
          imageUrl: item.image.sourceUrl,
          ctaLabel: item.ctaLabel,
          ctaTarget: item.ctaLink.target,
          ctaTitle: item.ctaLink.title,
          ctaUrl: item.ctaLink.url,
        })
        keyList.push(i + "a")
      }
    })

  // console.log("checkLength: ===> ",popupActiveList.length)
  // console.log("checkpopupActiveList: ===> ",popupActiveList)

  for (let j = 0; j < popupActiveList.length; j++) {
    if (popupActiveList.length > 1) {
      counter = counter + 1
      keyShowing = randomValueFromArray(keyList)
      developmentPopup.push(
        <Modal
          key={keyShowing.substring(0, 1)}
          isOpen={thisModal}
          onRequestClose={handlingModal}
          style={stylingModal}
          contentLabel="campaign modal"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close w-bg">
              <i className="fal fa-times" onClick={handlingModal}></i>
            </div>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-7 order-md-last modal-image">
                  <img
                    src={popupActiveList[keyShowing.substring(0, 1)]?.imageUrl}
                    className="mw-100"
                    alt="modal banner"
                  />
                </div>
                <div className="col-md-5 order-md-first">
                  <div className="modal-content">
                    <h2>
                      {popupActiveList[keyShowing.substring(0, 1)]?.title}
                    </h2>
                    <p>
                      <ReactMarkdown
                        source={
                          popupActiveList[keyShowing.substring(0, 1)]
                            ?.description
                        }
                        escapeHtml={false}
                      />
                    </p>
                    <div>
                      <Button
                        variant="secondary"
                        link={
                          popupActiveList[keyShowing.substring(0, 1)]?.ctaUrl
                        }
                        target="#"
                        className="btn-block mt-4"
                        id="btn-modal-popup"
                      >
                        {popupActiveList[keyShowing.substring(0, 1)]?.ctaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
      // break
    } else if (popupActiveList.length === 1) {
      counter = counter + 1
      developmentPopup.push(
        <Modal
          key={0}
          isOpen={thisModal}
          onRequestClose={handlingModal}
          style={stylingModal}
          contentLabel="campaign modal"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close w-bg">
              <i className="fal fa-times" onClick={handlingModal}></i>
            </div>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-7 order-md-last modal-image">
                  <img
                    src={popupActiveList[j].imageUrl}
                    className="mw-100"
                    alt="modal banner"
                  />
                </div>
                <div className="col-md-5 order-md-first">
                  <div className="modal-content">
                    <h2>{popupActiveList[j].title}</h2>
                    <p>
                      <ReactMarkdown
                        source={popupActiveList[j].description}
                        escapeHtml={false}
                      />
                    </p>
                    <div>
                      <Button
                        variant="secondary"
                        link={popupActiveList[j].ctaUrl}
                        target="#"
                        className="btn-block mt-4"
                        id="btn-modal-popup"
                      >
                        {popupActiveList[j].ctaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    if (counter == 1) {
      break
    }
  }
  return developmentPopup
}

const Layout = ({ children, altNav, newsSlug, isAmp, isGtag = true, isMicrodataProduct, }) => {
  const { lang } = useContext(LangContext)
  const { popupBanner, setPopup } = useContext(PopupContext)
  const [modal, setModal] = useState(false)
  const { data: dataPopup } = useQuery(GET_DATA_POPUP)
  let listOfPopup =
    dataPopup &&
    (lang === "ID"
      ? dataPopup.page.translation?.pop_up?.listPopUp
      : dataPopup.page?.pop_up?.listPopUp)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const handleModal = () => {
    setModal(!modal)
    setPopup(false)
    sessionStorage.setItem("popup", false)
  }

  useLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])

  // if key param in search result enable
  useEffect(() => {
    const paramUrl = window.location.search
    if (paramUrl && paramUrl.includes("?key=")){
      sessionStorage.setItem("search_key", paramUrl.split("?key=").join(""))
    }
  }, [])

  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    const body = document.querySelector("body")
    const navContents = document.querySelectorAll(
      ".megamenu-desktop[data-content]"
    )

    anchor.forEach(item => {
      const navigation = e => {
        const redirect = item.getAttribute("href")
        e.preventDefault()

        navContents.forEach(item => {
          item.classList.remove("show")
        })
        body.classList.remove("bd-page")
        wrapper.classList.remove("fadeIn")
        wrapper.classList.add("fadeOut")

        setTimeout(() => {
          navigate(redirect)
        }, 250)
      }
      if (
        item.getAttribute("href") &&
        !item.getAttribute("target") &&
        !item.getAttribute("download") === null
      ) {
        item.addEventListener("click", navigation)
      }
    })
  }, [])

  useEffect(() => {
    // get utm and put it in cookie
    getUtmParams(window.location.search)
    const splitUndefined = window.location.search
    const url = window.location.href.replace("?undefined=undefined", "")
    if (splitUndefined.includes("?undefined=undefined")) navigate(url)
  }, [])

  useEffect(() => {
    if (popupBanner && !sessionStorage.getItem("popup")) {
      setTimeout(() => {
        setModal(true)
      }, 2000)
    }
  }, [])

  useEffect(() => {
    const pathIdentifier = "/amp/"
    const pathname = window.location.pathname
    if(pathname.indexOf(pathIdentifier) > -1) {
      console.log("amp has deployed")
    }else{
      import (`../assets/scss/main.scss`)
    }
  }, [])

  return (
    <>
      <Helmet>
        {isGtag &&
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GTM_ID}`}></script>
        }
        {isGtag &&
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.GTM_ID}');
            `}
          </script>
        }
        {!isAmp ? (<>
        <script src={withPrefix("fb-pixel.js")} type="text/javascript" />
        <noscript>
          {`
          <img
            height="1"
            width="1"
            src="https://www.facebook.com/tr?id=1016449308879339&ev=PageView&noscript=1"
          />
          `}
        </noscript>
        </>
        ):(
          ""
        )}
      </Helmet>
      {!altNav ? <Navbar newsSlug={newsSlug} isAmp={isAmp} /> : <NavbarAlt />}
      {/* <Navbar /> */}
      {isMicrodataProduct ? (
        <main itemType="https://schema.org/Product" itemScope>
          {children}
          <ModalTag
            dataModal={listOfPopup}
            handlingModal={handleModal}
            thisModal={modal}
            stylingModal={customStyles}
          />
        </main>
      ):(
        <main>
          {children}
          <ModalTag
            dataModal={listOfPopup}
            handlingModal={handleModal}
            thisModal={modal}
            stylingModal={customStyles}
          />
        </main>
      )}
      <Footer newsSlug={newsSlug} isAmp={isAmp}/>
    </>
  )
}

export default Layout

import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Link, navigate } from "gatsby"

import { revealFromTop, revealCloseY } from "./animations"
import { Button } from "../anti/buttons/buttons"
// import { CardSlider } from "../ak-card-slider/card-slider"

const DesktopServicesSubmenu = ({
  menus,
  desktopSubmenu,
  handleDesktopSubmenu,
  navExpand,
  data,
  lang
}) => {
  // MOBILE SUBMENU - Animation
  // Element ref.
  let aboutMenuWrapper = useRef(null)
  let aboutMenuBackground = useRef(null)
  let aboutMenuContent = useRef(null)

  const childItems = lang === "EN"
    ? ((menus
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("service")
      )
    )?.childItems.nodes
    : ((menus
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      .menuItems.nodes.find(el =>
        el.url === "#" && (el.label.toLowerCase().includes("layanan") || el.label.toLowerCase().includes("jasa"))
      )
    )?.childItems.nodes

  // Reveal & close animation
  useEffect(() => {
    if (desktopSubmenu.clicked === false) {
      revealCloseY(aboutMenuContent)
      gsap.to(aboutMenuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(aboutMenuWrapper, { duration: 1, css: { display: "none" } })
    } else if (
      desktopSubmenu.clicked === true ||
      (desktopSubmenu.clicked === true && desktopSubmenu.initial === null)
    ) {
      gsap.to(aboutMenuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(aboutMenuContent, {
        duration: 0,
        opacity: 1,
        height: "auto",
      })
      gsap.to(aboutMenuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(aboutMenuContent)
    }
  }, [desktopSubmenu])
  return (
    <>
      <div className={`d-none d-${navExpand}-block`}>
        <div
          className="navbar-submenu light"
          ref={el => (aboutMenuWrapper = el)}
        >
          <div
            className="navbar-submenu-bg"
            ref={el => (aboutMenuBackground = el)}
            onClick={handleDesktopSubmenu}
          />
          <div
            className="navbar-submenu-content"
            ref={el => (aboutMenuContent = el)}
          >
            <div className="navbar-submenu-collapse">
              <div className="navbar-submenu-collapse-header">
                <button className="btn btn-link" onClick={handleDesktopSubmenu}>
                  {lang == "EN" ? "Back" : "Kembali"}
                </button>
              </div>
              <div className="navbar-submenu-collapse-content">
                <div className="container">
                  <h2>{lang == "EN" ? "Services" : "Layanan"}</h2>
                  <div className="row w-100">
                    <div className="col-md-4">
                      <ul className="navbar-nav mb-4">
                        {childItems?.map((item, i) => {
                          return (
                            <li key={i} className="nav-item">
                              <Link className="nav-link" activeClassName="active" to={item.url+"/"}>
                                {item.label}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                      <Link
                        className="btn btn-secondary btn-block"
                        to={`${lang == "EN" ? "" : "/id"}/contact-us/`}
                      >
                        {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopServicesSubmenu
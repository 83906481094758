import React, { useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import { Button } from "../anti/buttons/buttons"
import { staggerBackgroundIn, staggerBackgroundOut } from "./animations"

const MobileServicesSubmenu = ({
  mobileSubmenu,
  handleMobileSubmenu,
  navExpand,
  data,
  menusMobile,
  lang,
  isAmp
}) => {
  // MOBILE SUBMENU
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  // const menus =
  //   data &&
  //   data.wordPress.menus.nodes[1].menuItems.nodes.find(
  //     x => x.label === "Services"
  //   )

  const menus =
    lang == "EN" ? data.wordPress.menus.nodes[2].menuItems.nodes.find(
      x => x.label === "Services"
    )
      :
      data.wordPress.menus.nodes[3].menuItems.nodes.find(
        x => x.label === "Jasa"
      )
  const childItems = lang === "EN"
    ? ((menusMobile
      .find(el => el.slug === "navbar-menu"))
      .menuItems.nodes.find(el =>
        el.url === "#" && el.label.toLowerCase().includes("service")
      )
    )?.childItems.nodes
    : ((menusMobile
      .find(el => el.slug === "navbar-menu-indonesia" || el.slug === "navbar-menu-id"))
      .menuItems.nodes.find(el =>
        el.url === "#" && (el.label.toLowerCase().includes("layanan") || el.label.toLowerCase().includes("jasa"))
      )
    )?.childItems.nodes
  // Reveal & close animation
  useEffect(() => {
    if (mobileSubmenu.clicked === false) {
      // staggerContentOut(line7, line6, line5, line4, line3, line2, line1)
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
    } else if (
      mobileSubmenu.clicked === true ||
      (mobileSubmenu.clicked === true && mobileSubmenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      // staggerContentIn(line1, line2, line3, line4, line5, line6, line7)
    }
  }, [mobileSubmenu])

  return (
    <>
      {isAmp? (
        <amp-sidebar id={`side${menus.label.replace(/\s/g, '')}`} layout="nodisplay" side="right">
          <div role="button" aria-label="close sidebar" on={`tap:side${menus.label.replace(/\s/g, '')}.toggle`} tabindex="0" class="close-sidebar-left">{lang == "EN" ? "Back" : "Kembali"}</div>
          <h2 ref={el => (line1 = el)}>{lang == "EN" ? "Services" : "Layanan"}</h2>
          <ul className="navbar-nav-services mb-3">
            {childItems?.map((item, i) => (
              <li className="nav-item" key={i}>
                <div>
                  <Link className="nav-link" activeClassName="active" to={item.url+"/"} >
                    {item.label}
                  </Link>
                </div>
              </li>
            ))}
            <li>
              <Button
                variant="secondary btn-block"
                onClick={() => navigate(`${lang === "EN" ? "" : "/id"}/contact-us/`)}
              >
                {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
              </Button>
            </li>
          </ul>
        </amp-sidebar>
      ) : (
        <div className={`d-block d-${navExpand}-none`}>
          <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
            <div
              className="navbar-submenu-bg"
              ref={el => (menuBackground = el)}
              onClick={handleMobileSubmenu}
            />
            <div
              className="navbar-submenu-content"
              ref={el => (menuContent = el)}
            >
              <div className="navbar-submenu-collapse">
                <div className="navbar-submenu-collapse-header">
                  <button className="btn btn-link" onClick={handleMobileSubmenu}>
                    {lang == "EN" ? "Back" : "Kembali"}
                  </button>
                </div>
                <div className="navbar-submenu-collapse-content d-block">
                  <div className="overflow-hidden">
                    <h2 ref={el => (line1 = el)}>{lang == "EN" ? "Services" : "Layanan"}</h2>
                  </div>
                  <ul className="navbar-nav mb-3">
                    {childItems?.map((item, i) => (
                      <li className="nav-item" key={i}>
                        <div>
                          <Link className="nav-link" activeClassName="active" to={item.url+"/"} >
                            {item.label}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Button
                    variant="secondary btn-block"
                    onClick={() => navigate(`${lang === "EN" ? "" : "/id"}/contact-us/`)}
                  >
                    {lang == "EN" ? "Contact Us" : "Hubungi Kami"} <i className="fas fa-arrow-right ml-1"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileServicesSubmenu